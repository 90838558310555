function EncryptData(inParamsStringified, inKey) {
    try {
        //Creating the Vector Key
        var iv = CryptoJS.enc.Hex.parse(inKey);
        console.log('iv hexed: ', iv);
        //Encoding the Password
        var key = CryptoJS.enc.Hex.parse(inKey);
        console.log('key hexed: ', key);
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(inParamsStringified), key, {
            mode: CryptoJS.mode.CBC,
            iv: iv,
            padding: CryptoJS.pad.Pkcs7
        });
        console.log('CryptoJS iv: ', encrypted.iv.toString());
        console.log('CryptoJS key: ', encrypted.key.toString());
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    } catch (err) {
        return false;
    }
  }
  function azigoSSO(sso_key, end_point, cc_no) {
    var a = Date.now().toString();
    var programKey = sso_key,
                userId = cc_no,
                redirectTo = "",
                subId = "",
                email = "",
                message = JSON.stringify({
                    timestamp: Date.now().toString(), // required
                    userId: userId, // required
                    redirectTo: redirectTo,
                    subId: subId,
                    email: email
                });
  
    if (programKey === '' || userId === '') {
        alert('SSO Key and User ID are required fields');
        return
    }
  
    var encrypted = EncryptData(message, programKey);
    window.open(end_point + encrypted);
  }
  